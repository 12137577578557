<template>
  <header-top :app="app" />
  <router-view />
  <footer-index :app="app" />
</template>

<script>
import HeaderTop from "./components/HeaderTop.vue";
import FooterIndex from "./components/FooterIndex.vue";

import EventBus from "./common/EventBus";

import axios from "./services/api";

export default {
  name: "App",

  components: {
    HeaderTop,
    FooterIndex,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    app_id() {
      return process.env.VUE_APP_TENANTID;
    },
  },

  data() {
    return {
      app: {},
    };
  },

  created() {
    this.getData();
  },

  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },

  beforeUnmount() {
    EventBus.remove("logout");
  },

  methods: {
    async getData() {
      let r = await axios.get(`app/${this.app_id}/details`);
      this.app = r.data.app;
      document.title = this.app.name;

      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = this.app.logo;
    },

    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
$primary: #119744;
$body_bg: #f5f6f7;
$white: #ffffff;

@import "./assets/scss/toastr.css";
@import "./assets/scss/_dropbax";
@import "vue-search-select/dist/VueSearchSelect.css";
@import "axios-progress-bar/dist/nprogress.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "vue-search-select/dist/VueSearchSelect.css";
@import "./ltr/fonts/flaticon/flaticon.css";
@import "./ltr/fonts/icofont/icofont.min.css";
@import "./ltr/fonts/fontawesome/fontawesome.min.css";
@import "./ltr/vendor/venobox/venobox.min.css";
@import "./ltr/vendor/slickslider/slick.min.css";
@import "./ltr/vendor/niceselect/nice-select.min.css";
@import "./ltr/css/index.css";
@import "./ltr/css/main.css";
@import "./ltr/css/product-details.css";

.avatar-lg {
  height: 4.5rem;
  width: 4.5rem;
}

.user-dp {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 45%;
  margin-top: -5px;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--ct-card-bg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.bg-soft-primary {
  --ct-bg-opacity: 0.25;
  background-color: rgba($primary, var(--ct-bg-opacity)) !important;
}

.bg-soft-success {
  --ct-bg-opacity: 0.25;
  background-color: rgba($success, var(--ct-bg-opacity)) !important;
}

.bg-soft-info {
  --ct-bg-opacity: 0.25;
  background-color: rgba($info, var(--ct-bg-opacity)) !important;
}

.bg-soft-warning {
  --ct-bg-opacity: 0.25;
  background-color: rgba($warning, var(--ct-bg-opacity)) !important;
}

.bg-soft-secondary {
  --ct-bg-opacity: 0.25;
  background-color: rgba($secondary, var(--ct-bg-opacity)) !important;
}

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.text-right {
  text-align: right !important;
}

input.form-control, select.form-control {
  height: 30px !important;
}

a {
  text-decoration: none !important;
}

.btn {
  text-transform: capitalize;
}

.toast {
  border-radius: 5px;
}

.toast-error {
  background-color: #dd3445 !important;
}

.toast-success {
  background-color: #119744 !important;
}

.btn-primary {
  color: $white;
}

.card-product-box:hover {
  border: 1px solid $primary;
}

.page-link {
  padding: 0 !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
}

input[type="number"] {
  text-align: right !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.product-image {
  height: 24vh !important;
}

.grid-product-image {
  height: 20vh !important;
}

.list-product-image {
  height: 20vh !important;
}

.nav-item {
  cursor: pointer;
}

h3.view-price small {
  text-transform: lowercase !important;
}

textarea::placeholder{
  text-transform: lowercase;
}

@media screen and (max-width: 992px) {
  .suggest-card img {
    width: 40vw;
    height: 10vh;
  }
}

@media screen and (min-width: 992px) {
  .suggest-card img {
    width: 13vw;
    height: 15vh;
  }
}
</style>
