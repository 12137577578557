<template>
  <footer class="footer-part bg-white">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-xl-4">
          <div class="footer-widget">
            <a class="footer-logo" href="#">
              <img :src="app.logo" alt="logo" class="rounded" />
            </a>
            <p class="footer-desc" v-html="app.brief_description"></p>
            <ul class="footer-social">
              <li><a class="icofont-facebook" href="#"></a></li>
              <li><a class="icofont-twitter" href="#"></a></li>
              <li><a class="icofont-linkedin" href="#"></a></li>
              <li><a class="icofont-instagram" href="#"></a></li>
              <li><a class="icofont-pinterest" href="#"></a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-xl-4">
          <div class="footer-widget contact">
            <h3 class="footer-title">contact us</h3>
            <ul class="footer-contact">
              <li>
                <i class="icofont-ui-email"></i>
                <p>
                  <span v-for="(email, e) in emails" :key="e">
                    {{ email.value }}
                  </span>
                </p>
              </li>
              <li>
                <i class="icofont-ui-touch-phone"></i>
                <p>
                  <span v-for="(phone, e) in phones" :key="e">
                    {{ phone.value }}
                  </span>
                </p>
              </li>
              <li v-for="(address, e) in addresses" :key="e">
                <i class="icofont-location-pin"></i>
                <p class="pt-3">
                  {{ address.value }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-sm-6 col-xl-4">
          <div class="footer-widget">
            <h3 class="footer-title">quick Links</h3>
            <div class="footer-links">
              <ul>
                <li><a href="#">My Account</a></li>
                <li><a href="#">Order History</a></li>
                <li><a href="#">Order Tracking</a></li>
                <li><a href="#">Best Seller</a></li>
                <li><a href="#">New Arrivals</a></li>
              </ul>
              <ul>
                <li><a href="#">Location</a></li>
                <li><a href="#">Affiliates</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">Carrer</a></li>
                <li><a href="#">Faq</a></li>
              </ul>
            </div>
          </div>
        </div> -->
        <div class="col-sm-6 col-xl-4">
          <div class="footer-widget">
            <h3 class="footer-title">Who we are</h3>
            <p class="footer-desc" v-html="app.description"></p>
            <div class="footer-app">
              <a href="#"><img src="../ltr/images/google-store.png" alt="google" /></a>
                <!-- <a href="#"><img src="../ltr/images/app-store.png" alt="app"/></a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="footer-bottom">
            <p class="footer-copytext">
              &copy; All Copyrights Reserved by <a href="#">Smartify Africa</a>
            </p>
            <div class="footer-card">
              <a href="#"
                ><img src="../ltr/images/payment/jpg/01.jpg" alt="payment" /></a
              ><a href="#"
                ><img src="../ltr/images/payment/jpg/02.jpg" alt="payment" /></a
              ><a href="#"
                ><img src="../ltr/images/payment/jpg/03.jpg" alt="payment" /></a
              ><a href="#"
                ><img src="../ltr/images/payment/jpg/04.jpg" alt="payment"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: ["app"],

  computed: {
    phones() {
      if (this.app === undefined || this.app.addresses === undefined) {
        return "";
      }

      return Object.values(this.app.addresses).filter((address) => {
        if (address.type === "phone") {
          return address;
        }
      });
    },

    emails() {
      if (this.app === undefined || this.app.addresses === undefined) {
        return "";
      }

      return Object.values(this.app.addresses).filter((address) => {
        if (address.type === "email") {
          return address;
        }
      });
    },

    addresses() {
      if (this.app === undefined || this.app.addresses === undefined) {
        return "";
      }

      return Object.values(this.app.addresses).filter((address) => {
        if (address.type === "address") {
          return address;
        }
      });
    },
  },
};
</script>
